import { useState } from "react";
export default function Player({initialName,symbol, isActive, onChangeName}){
    const [playerName, setplayerName]  = useState(initialName);
    const [IsEditing, setIsEditing]  = useState(false);
    function Handleediting(){
      setIsEditing(editing=>!editing);
      if(IsEditing){
        onChangeName(symbol,playerName);
      }
    }
    function handleChange(event){
      setplayerName(event.target.value)
    }
    let editablePlayerName=  <span className="player-name">{playerName}</span>;
    if(IsEditing){
      editablePlayerName = <input type="text" required className="player-name" value={playerName} onChange={handleChange}/>;
    }
    return <>
          <li className={isActive?'active':undefined}>
            <span className="player">
              {editablePlayerName}
              <span className="player-symbol">{symbol}</span>
            </span>
            <button onClick={Handleediting}>{IsEditing?"Save":"Edit"}</button>
          </li>
    </>
}