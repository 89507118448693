import React from 'react'

export default function SkillCard(props) {
  return (
    <>
      <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
        <div className="icon"><i className="bi bi-card-checklist"></i></div>
        <h4 className="title">{props.title !== null ? props.title:""}</h4>
        <p className="description">{props.description !== null ? props.description:""}</p>
      </div>
    </>
  )
}
