import React from 'react'
import NavigationMenu from './navigationmenu/NavigationMenu'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import About from '../about/About';
import Projects from '../projects/Projects';
import Skills from '../skills/skills';
import Home from '../home/Home';
import Tictactoe from '../tictactoe/Tictactoe';

const router = createBrowserRouter([
  {
    element: <NavigationMenu />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/skills",
        element: <Skills />,
      },
      {
        path: "/tttgame",
        element: <Tictactoe />,
      }
    ],
  }
]);
export default function Layout() {
  
  return (
      <RouterProvider router={router} />
  )
}
