import React, { useEffect } from 'react'
import Typed from 'typed.js'


export default function Home() {
  useEffect(() => {
    document.getElementById("header").classList.remove("drawerOpen");
    document.getElementById("mobile-nav-toggler").classList.add("bi-list");
    document.getElementById("mobile-nav-toggler").classList.remove("bi-x-lg");
    document.title = 'Portfolio';
    document.getElementsByTagName("body")[0].classList.add("index");
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      } 
    }
    const typed = select('.typed')
    if (typed) {
      let typed_strings = typed.getAttribute('data-typed-items')
      typed_strings = typed_strings.split(',')
      new Typed('.typed', {
        strings: typed_strings,
        loop: true,
        typeSpeed: 100,
        backSpeed: 50,
        backDelay: 2000
      },[]);
    }
  });
  return (
    <>
      <section id="hero" className="d-flex flex-column justify-content-center">
        <div className="hero-container" data-aos="fade-in">
        <h1>Vishvas Mishra</h1>
        <p>I'm <span className="typed" data-typed-items="Frontend developer, Shopify theme developer, Bigcommerce theme developer"></span></p>
        </div>
      </section>
    </>
  )
}
