import React from 'react'
import Player from './supportivejsx/Player';
import Gameboard from './supportivejsx/Gameboard';
import Log from './supportivejsx/Log';
import { WINNING_COMBINATIONS } from '../../winning-combinations';
import Gameover from './supportivejsx/Gameover';
import { useState } from 'react';
const initialGameboard = [
    [null, null, null],
    [null, null, null],
    [null, null, null],
  ]
  
  function derivedActivePlayer(gameTurn,playersName){
    console.log()
    let currPlayer = "X";
    if(gameTurn.length > 0 && gameTurn[0].player === "X"){
      currPlayer="O";
    }
    return currPlayer;
  }
  
  function derivedWinner(gameBoard,playersName){
    let winner;
    for (let combination of WINNING_COMBINATIONS){
      const firstPositionSymbol= gameBoard[combination[0].row][combination[0].column]
      const secondPositionSymbol= gameBoard[combination[1].row][combination[1].column]
      const thirdPositionSymbol= gameBoard[combination[2].row][combination[2].column]
      if(firstPositionSymbol && firstPositionSymbol === secondPositionSymbol && firstPositionSymbol === thirdPositionSymbol){
        winner = playersName[firstPositionSymbol];
      }
    }
    return winner;
  }
  
  function derivedGameboard (gameTurn){
    let gameBoard = [...initialGameboard.map(array => [...array])];
    for (const turn of gameTurn){
        const {square, player}=turn;
        const {row,col}=square;
        gameBoard[row][col]=player;
    }
    return gameBoard;
  }
export default function Tictactoe() {
    const [playersName, setPlayersName]=useState({
        "X":"Player 1",
        "O":"Player 2"
      })
      const [gameTurn, SetGameTurn]=useState([]);
      const gameBoard=derivedGameboard(gameTurn);
      const winner =derivedWinner(gameBoard,playersName);
      const activePlayer = derivedActivePlayer(gameTurn,playersName);
      function handleSelectSquare(rowIndex, colIndex){
        SetGameTurn((prevTurns)=>{
          const currPlayer = derivedActivePlayer(prevTurns);
          const updatedTurn = [
           {square: {row:rowIndex, col:colIndex},player:currPlayer},
            ...prevTurns
          ]
          return updatedTurn;
        })
      }
      function handleRestart(){
        SetGameTurn(()=>{
          return [];
        })
      }
      function setPlayers(symbol, newName){
        setPlayersName((prevPlayer)=>{
          return{
            ...prevPlayer,
            [symbol]:newName
          }
        })
      }
      const hasDraw = gameTurn.length === 9 && !winner;
      return (
        <main id="tictactoe-main">
          <h1 className="text-center">Tic-tac-toe Game</h1>
          <div id="game-container">
            <ol id="players" className="highlight-player">
              <Player initialName="Player 1" symbol="X" isActive={activePlayer==="X"} onChangeName={setPlayers}/>
              <Player initialName="Player 2" symbol="O" isActive={activePlayer==="O"} onChangeName={setPlayers}/>
            </ol>
            {(winner || hasDraw) && <Gameover winner={winner} onRestart={handleRestart}/>}
            <Gameboard onSelect={handleSelectSquare}  board={gameBoard}/>
          </div>
          <Log gturn={gameTurn} playersname={playersName} />
        </main>
      )
}
