import React from 'react'

export default function ExperienceCard(props) {
  return (
    <>
      <div className="resume-item">
            <h4>{props.cname != null?props.cname:""}</h4>
            <p><em>{props.designation != null?props.designation:""}</em></p>
            <h5>{props.tenure != null?props.tenure:""}</h5>
            <p><em>{props.address != null?props.address:""}</em></p>
            <ul>
            {props.utlzdskills.map((data)=>{
                return <li key={data.skillname}>{data.skillname}</li>
            })}
            </ul>
        </div>
    </>
  )
}
