import React, { useEffect } from 'react'
import SkillCard from './SkillCard'

export default function Skills() {
  const skillarr=[
    {
      "title":"SHOPIFY",
      "description":"Worked on 180+ Projects. All are successfully delivered"
    },
    {
      "title":"SHOPIFY PLUS",
      "description":"Worked on 20+ projects. Most of the projects are from retained clients."
    },
    {
      "title":"BIGCOMMERCE",
      "description":"Worked on 3 projects with lots of custom functionality."
    },
    {
      "title":"HUBSPOT",
      "description":"Worked on 2 projects on maintainance task only like new page creation and creating custom option is customization window."
    },
    {
      "title":"CUSTOM APP",
      "description":"Worked on middle layer using PHP and shopify webhooks for custom functionalities."
    },
    {
      "title":"PUBLIC APP INTEGRATION",
      "description":"Great experience on Public app setup within store like Recharge, Klaviyo, etc."
    },
    {
      "title":"HTML",
      "description":"Started carrier with handling frontend of projects with backend development."
    },
    {
      "title":"CSS",
      "description":"Handling frontend of projects requires good knowledge of CSS."
    },
    {
      "title":"JAVASCRIPT",
      "description":"Worked on modification of theme default JS to make app setup work with functionalities."
    },
    {
      "title":"JQUERY",
      "description":"Developed many custom functionalities using Jquery on websites."
    },
    {
      "title":"VUE.JS USING CDN LINK",
      "description":"Used Vue.js using CDN on shopify to create custom functionality and created predictive search option in one of my project Climbon."
    },
    {
      "title":"REACT BASICS",
      "description":"Creating the custom functionality using react over shopify store like Ajax search, Creating the quiz etc."
    }
  ];
  useEffect(()=>{
    document.getElementById("header").classList.remove("drawerOpen");
    document.getElementById("mobile-nav-toggler").classList.add("bi-list");
    document.getElementById("mobile-nav-toggler").classList.remove("bi-x-lg");
    document.title = 'Portfolio - Skills';
    document.getElementsByTagName("body")[0].classList.remove("index");
  },[])
  return (
    <>
      <section id="services" className="services">
      <div className="container">
        <div className="section-title">
          <h2>Skills</h2> 
        </div>

        <div className="row">
          {skillarr.map((skill)=>{
            return <SkillCard key={skill.title} title={skill.title} description={skill.description}/>
          })}
        </div>

      </div>
    </section>
    </>
  )
}
