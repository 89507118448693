export default function Gameover({winner,onRestart}) {
    return (
      <div id="game-over">
        <h2>Game Over!</h2>
          {winner ? <p>{winner} Won!</p>:<p>Match draw</p>}
          <p>
              <button onClick={onRestart} >Restart !!!!</button>
          </p>
      </div>
    )
  }
  