export const projectarr=[
    {
      "link":"https://alfred.la/",
      "projType":"shopify",
      "imgUrl":"assets/img/portfolio/Alfred_35004a86-95c8-48e1-855f-6c0df38bf1b4.png",
      "title":"<strong>Alfred Coffee</strong> is an ecommerce Site selling coffee, tea and merch. Complex implmented functionality in the website is it's menu. When we hover on a menu link then image on the right hand side changes. Moreover website had AJAX ATC, Recharge app and Collection page AJAX filter."
    },
    {
      "link":"https://www.metropoliscoffee.com/",
      "projType":"shopifyp",
      "imgUrl":"assets/img/portfolio/Online-Coffee-Store-Best-Coffee-Roasters-Metropolis-Coffee.png",
      "title":"<strong>Metropolis</strong> is an ecommerce Site selling coffee beans, coffee filters etc. This webite is having AJAX ATC and Rebuy with Recharge integration. There is also a wholeseller website of the Metropolis Coffee on Shopify plus built by me called <a href='https://wholesale-metropolis-coffee-company.myshopify.com/' target='_blank' WHOLESALE METROPOLIS COFFEE. Wholesale website is using lock feature and only Wholepartners can access products within the site"
    },
    {
      "link":"https://www.gillmarine.com",
      "projType":"bc",
      "imgUrl":"assets/img/portfolio/gill.png",
      "title":"<strong>Gill Marine</strong> is an ecommerce Site selling wateproof clothing."
    },
    {
      "link":"https://sourtooth.com/",
      "projType":"shopify",
      "imgUrl":"assets/img/portfolio/No-Sour-Like-Ours-_-Sour-Tooth.png",
      "title":"<strong>Sour Tooth</strong> is an ecommerce site selling Sour candy, Sour belts etc. Best and complex part in this website is it's bundle builder functionality made with custom code when check shop page for purchase."
    },
    {
      "link":"https://www.kttape.com/",
      "projType":"shopifyp",
      "imgUrl":"assets/img/portfolio/KT-Tape-Therapeutic-Kinesiology-Tape.png",
      "title":"<strong>KT Tape</strong> is an ecommerce Site selling sports tape for support from injury. Website collection page is having variants list on product cards and clicking on that images are changing. Moreover it is having Subscription app integration and AJAX ATC option."
    },
    {
      "link":"https://shakuff.com/",
      "projType":"shopify",
      "imgUrl":"assets/img/portfolio/Shakuff-Custom-Lighting.png",
      "title":"<strong>Shakuff</strong> is an ecommerce site selling chandeliers, Table Lamps etc. Moreover, implementation of it's menu using custom code when hovering on the submenu links the right hand site image changes accordingly. Further bast part in the website is managing the collection and subcollection data with different data on each collection and subcollection page."
    },
    {
      "link":"https://www.roomtogrow.co.uk/",
      "projType":"bc",
      "imgUrl":"assets/img/portfolio/roomtogrow.png",
      "title":"<strong>Room to grow</strong> is an ecommerce Site selling products to build children rooms like Bunk Beds, House beds etc"
    },
    {
        "link":"https://buyart.mallgalleries.org.uk/",
        "projType":"bc",
        "imgUrl":"assets/img/portfolio/mallGalleries.png",
        "title":"<strong>Mall Gallery</strong> is an ecommerce Site selling Paintings for different artists. Conducts exibitions as well"
      },
    {
      "link":"https://plentygoods.com/",
      "projType":"shopify",
      "imgUrl":"assets/img/portfolio/Plenty-Goods.png",
      "title":"<strong>Plenty Goods</strong> is an ecommerce Site selling healthy food and snacks. Website is having lots of app integration like REBUY, RECHARGE ETC. Making the bundle of product is making this website more powerful with subscription functionality using recharge."
    },
    {
      "link":"https://babytrend.com/",
      "projType":"shopifyp",
      "imgUrl":"assets/img/portfolio/Baby-Trend_-Car-Seats-Strollers-High-Chairs-Nursery-and-More.png",
      "title":"<strong>Baby Trends</strong> is an ecommerce Site selling baby strollers, seats etc."
    },
    {
      "link":"https://laudividni.com/",
      "projType":"shopify",
      "imgUrl":"assets/img/portfolio/Impeccable-Bags-Custom-made-in-the-USA-Laudi-Vidni.png",
      "title":"<strong>Laudividni</strong> is an ecommerce Site selling customized bags. Making customized bags according to color, custom text etc making this website different from the other shopify bag selling website. Moreover this website is having a individual website which deals with making order for employees of different collaborating Organizations called <a target='_blank' href='https://individualxlaudividni.com/'>Laudividni Individual</a>. It is having collection lock feature. Single employee cannot buy product from two collections simultaneously."
    },
    {
      "link":"https://gossamergear.com/",
      "projType":"shopifyp",
      "imgUrl":"assets/img/portfolio/Gossamer-Gear-take-less-do-more.png",
      "title":"<strong>Gossamer Gear</strong> is an ecommerce Site selling product related to trekking activity. This Website is having AJAX ATC with custom code bulilded Frequently bought togather product on product page which vary according to each product."
    },
    {
      "link":"https://materialgood.com/",
      "projType":"shopifyp",
      "imgUrl":"assets/img/portfolio/Material-Good-I-A-Unique-Luxury-Shopping-Experience.png",
      "title":"<strong>Material Goods</strong> is an ecommerce Site selling fine jewelry. Website is having Megamenu with AJAX ATC option on the product page."
    },
    {
      "link":"https://shopatfmh.com/",
      "projType":"bc",
      "imgUrl":"assets/img/portfolio/shopatfremation.png",
      "title":"<strong>Shop at freemeson's hall</strong> is an ecommerce Site selling books, clothes etc."
    }

  ]