export const exparr=[
    {
      "cname":"Williams Commerce Pvt. Ltd.",
      "designation":"Senior Software Developer",
      "tenure":"MAR 2023 - Present",
      "address":"Sector 62, Noida",
      "utlzdskills":[
        {
          "skillname":"Bigcommerce"
        },
        {
          "skillname":"Shopify"
        },
        {
          "skillname":"Shopify Plus"
        }
      ]
    },
    {
      "cname":"Iskpro Pvt. Ltd.",
      "designation":"Shopify Developer",
      "tenure":"MAY 2020 - MAR 2023",
      "address":"Sector 58, Noida",
      "utlzdskills":[
        {
          "skillname":"Shopify"
        },
        {
          "skillname":"Shopify CLI"
        },
        {
          "skillname":"Shopify Plus"
        }
      ]
    },
    {
      "cname":"Iskpro Pvt. Ltd.",
      "designation":"Shopify Developer",
      "tenure":"OCT 2018 - SEPT 2019",
      "address":"Sector 58, Noida",
      "utlzdskills":[
        {
          "skillname":"Shopify"
        },
        {
          "skillname":"Hubspot"
        },
        {
          "skillname":"Shopify Plus"
        }
      ]
    },
    {
      "cname":"ENS Enterprises Pvt. Ltd.",
      "designation":"Software Developer grade 2",
      "tenure":"APR 2017 - OCT 2018",
      "address":"Sector 63, Noida",
      "utlzdskills":[
        {
          "skillname":"Shopify"
        },
        {
          "skillname":"Frontend Development"
        },
        {
          "skillname":"Shopify Plus"
        }
      ]
    }
  ];