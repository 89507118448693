import React, { useEffect, useRef, useState } from 'react'
import Isotope from 'isotope-layout';
import GLightbox from 'glightbox';
import ProjCard from './ProjCard';
import { projectarr } from '../../projects';

export default function Projects() {

  const isotope = useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*')
  const [preVfilterKey, setpreVFilterKey] = useState(filterKey);

  // initialize an Isotope object with configs
  useEffect(() => {
    document.getElementById("header").classList.remove("drawerOpen");
    document.getElementById("mobile-nav-toggler").classList.add("bi-list");
    document.getElementById("mobile-nav-toggler").classList.remove("bi-x-lg");
    document.title = 'Portfolio - Projects';
    new GLightbox({
      selector: '.portfolio-lightbox'
    });
  },[])
  // handling filter key change
  useEffect(() => {
    setpreVFilterKey("*");
    // setTimeout(function(){
    if(preVfilterKey !== filterKey){
    isotope.current = new Isotope('.portfolio-container', {
      itemSelector: '.portfolio-item',
      layoutMode: 'fitRows',
    })
    filterKey === '*'
      ? isotope.current.arrange({filter: `*`})
      : isotope.current.arrange({filter: `.${filterKey}`})

    return () => isotope.current.destroy()
  }
  // },500)
  }, [filterKey,preVfilterKey])

  const handleFilterKeyChange = key => () => {setFilterKey(key)}
  return (
    <> 
      <section id="portfolio" className="portfolio">
      <div className="container">

        <div className="section-title">
          <h2>Projects</h2>
          <p>I had worked on 180+ Shopify, 22+ Shopify Plus and 3 Bigcommerce Projects. Below are the details and links of recent projects that I had successfully completed and delivered to client.</p>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li  onClick={handleFilterKeyChange('*')} className={filterKey==="*"?"filter-active":""}>All</li>
              <li  onClick={handleFilterKeyChange('filter-shopify')} className={filterKey==="filter-shopify"?"filter-active":""}>Shopify</li>
              <li  onClick={handleFilterKeyChange('filter-shopifyp')} className={filterKey==="filter-shopifyp"?"filter-active":""}>Shopify Plus</li>
              <li  onClick={handleFilterKeyChange('filter-bc')} className={filterKey==="filter-bc"?"filter-active":""}>Bigcommerce</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
          {projectarr.map((project)=>{
            return <ProjCard key={project.link} projType={project.projType} link={project.link} imgUrl={project.imgUrl} title={project.title} />
          })}
        </div>

      </div>
    </section>
    </>
  )
}
