import React from 'react'
import {  NavLink, Outlet } from 'react-router-dom'
import Footer from '../footer/Footer'

export default function NavigationMenu() {
  function openMenu(){
    if(document.getElementById("header").classList.contains("drawerOpen")){
      document.getElementById("header").classList.remove("drawerOpen");
      document.getElementById("mobile-nav-toggler").classList.add("bi-list");
      document.getElementById("mobile-nav-toggler").classList.remove("bi-x-lg");
    }
    else{
      document.getElementById("header").classList.add("drawerOpen");
      document.getElementById("mobile-nav-toggler").classList.remove("bi-list");
      document.getElementById("mobile-nav-toggler").classList.add("bi-x-lg");
    }
  }
  return (
    <>
      <i className="bi bi-list mobile-nav-toggle d-xl-none" onClick={openMenu} id="mobile-nav-toggler"></i>
      <header id="header">
        <div className="d-flex flex-column">
            <div className="profile">
                <img src="./assets/img/profile-img.jpg" alt="" className="img-fluid rounded-circle"/>
                <h1 className="text-light"><a href="/">Vishvas Mishra</a></h1>
                <div className="social-links mt-3 text-center">
                <a href="mailto:technicalvishvas@gmail.com" className="gmail"><i className="bx bxl-gmail"></i></a>
                <a href="//api.whatsapp.com/send?phone=+918802138952&text=Hi" className="whatsapp"><i className="bx bxl-whatsapp"></i></a>
                <a rel="noreferrer" href="https://www.linkedin.com/in/vishvas-mishra-26a637230/" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
            </div>

            <nav id="navbar" className="nav-menu navbar">
                <ul>

                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about">About</NavLink></li>
                <li><NavLink to="/projects">Projects</NavLink></li>
                <li><NavLink to="/skills">Skills</NavLink></li>
                <li><NavLink to="/assets/img/portfolio/Vishvas_Mishra.pdf" download="Vishvas_Mishra.pdf" target='_blank'
        rel='noopener noreferrer'>Download Resume</NavLink></li>
                <li><NavLink to="tel:+918802138952">Contact</NavLink></li>
                <li><NavLink to="/tttgame">Play Tic-tac-toe</NavLink></li>
                </ul>
            </nav>
         </div>
        </header>
        <main id="main">
        <Outlet />
        </main>
        <Footer/>
    </>
  )
}
