import React, { useEffect } from 'react'
import ExperienceCard from './ExperienceCard'
import { exparr } from '../../experiences';

export default function About() {
  useEffect(()=>{
    document.getElementById("header").classList.remove("drawerOpen");
    document.getElementById("mobile-nav-toggler").classList.add("bi-list");
    document.getElementById("mobile-nav-toggler").classList.remove("bi-x-lg");
    document.title = 'Portfolio - About';
    document.getElementsByTagName("body")[0].classList.remove("index");
  },[])
  return (
    <>
      <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>About</h2>
          <p className="">
              Experienced Shopify theme developer with a demonstrated history of working in the information technology and services industry. Skilled in <strong>English Communication</strong>, <strong>Shopify</strong>, and <strong>Bigcommerce</strong>. <br />Strong information technology professional with a Bachelor of Technology - BTech focused in Computer Science from Dr. A.P.J. Abdul Kalam Technical University. I am a Shopify theme developer having 6+ years of experience in IT. I had worked on Shopify theme development from scratch and also worked on developing custom options on existing Shopify free themes like DAWN, DEBUT and many more. I had also worked on Shopify developed App integration which are exists on the Shopify app store and also worked on the Private Apps creation for managing the API for creating the middle layer functionalities on third party servers who are interact with Shopify for the store data modification.
            </p>
            <p className="">
            Furthermore, I have a good knowledge of <strong>HTML</strong>, <strong>CSS</strong>, <strong>JAVASCRIPT</strong> and <strong>JQUERY</strong> in which HTML knowledge is must for Shopify liquid code writing. I also had a good experience of client handling.
            </p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Sumary</h3>
            <div className="resume-item pb-0">
              <h4>Vishvas Mishra</h4>
              <p><em>Innovative and deadline-driven Shopify theme developer and Bigcommerce theme developer with 6+ years of experience.</em></p>
              <ul>
                <li>Sector 49, Noida</li>
                <li>+91 8802138952</li>
                <li>technicalvishvas@gmail.com</li>
              </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              <h4>BACHELOR OF TECHNOLOGY</h4>
              <h5>2016</h5>
              <p><em>APJ Abdul Kalam Technical University, Uttar Pradesh</em></p>
              <p>Successfully completed Bachelor of technology in Computer science trade with aggregate 65%. </p>
            </div>
            <div className="resume-item">
              <h4>Class 12TH</h4>
              <h5>2012</h5>
              <p><em>RamNiranjan Jhunjhunwala College, Mumbai, Maharashtra</em></p>
              <p>Completed 12th class with Science Trade with aggregate 64%. </p>
            </div>
            <div className="resume-item">
              <h4>Class 10TH</h4>
              <h5>2010</h5>
              <p><em>Hindi Bal Vidya Mandir, Mumbai, Maharashtra</em></p>
              <p>Completed 10th class with aggregate 88%. </p>
            </div>
            <h3 className="resume-title">ADDITIONAL SKILLS</h3>
            <div className="resume-item">
              <ul>
                <li>Shopify website speed optimization</li>
                <li>Shopify website ADA compliance</li>
                <li>Google tag manager for custom event tracking</li>
                <li>Facebook pixel integration</li>
                <li>Tiktok pixel integration</li>
                <li>Yahoo Gemini tracking integration</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">Professional Experience</h3>
            {exparr.map((data)=>{
             return <ExperienceCard key={data.tenure} cname={data.cname} designation={data.designation} tenure={data.tenure} address={data.address} utlzdskills={data.utlzdskills} />
            })}
          </div>
        </div>

      </div>
    </section>
    </>
  )
}
