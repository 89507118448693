import React from 'react'

export default function ProjCard(props) {
    var classVal=`col-lg-6 col-md-6 portfolio-item filter-${props.projType}`
  return (
    <>
      <div className={classVal}>
        <div className="portfolio-wrap">
            <img src={props.imgUrl !== null ?props.imgUrl:""} className="img-fluid" alt=""/>
            <div className="portfolio-links">
            <a href={props.imgUrl !== null ?props.imgUrl:""} data-gallery="portfolioGallery" className="portfolio-lightbox" title={props.title !== null ?props.title:""}><i className="bx bx-plus"></i></a>
            <a rel="noreferrer" target="_blank" href={props.link !== null ?props.link:""} title="More Details"><i className="bx bx-link"></i></a>
            </div>
        </div>
      </div>
    </>
  )
}
