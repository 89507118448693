import React from 'react'

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="container">
        <div className="copyright">
            &copy; Copyright <strong><span>Vishvas</span></strong>
        </div>
        <div className="credits">
            Designed by <a href="https://itsmevishvas.info/">Vishvas Mishra</a>
        </div>
        </div>
    </footer>
    </>
  )
}
