// import logo from './logo.svg';
import { StrictMode } from 'react';
import Layout from '../layout/Layout';
import './App.css';

function App() {
  return (
    <StrictMode>
      <Layout/>
      </StrictMode>
  );
}

export default App;
